(function($){

    var _window;

    var IDEC = {

        main : null,
        header : null,
        footer : null,
        $menu : null,
        $video : null,
        lastScrolTop : false,
        timeResize : false,
        currentMouseY : -1,
        lastGadget : false,
        modalNews = false,

        init : function(){

            _window = $( window );

            IDEC.main = document.querySelector( 'body > div' );
            if( !IDEC.main ) return true;

            //  Binders
            IDEC.bindLinks();
            IDEC.bindHeader();
            IDEC.bindHero();
            IDEC.bindSliders();
            IDEC.bindFooter();
            IDEC.bindScroll();

        },

        bindLinks(){
            document.querySelectorAll( 'a' ).forEach( link => {
                if( link.href.indexOf( 'idec.org' ) >= 0 ){
                    link.href = link.href + '?utm_medium=site&utm_source=campanha-rotulagem&utm_campaign=campanha-rotulagem&utm_content=lp-deolho-nosrotulos&utm_term=' + link.dataset.term;
                }
            });
        },

        // Hero
        bindHero : function(){

            const scrollDown = document.querySelector( '[data-action="scroll-down"]' );
            scrollDown.addEventListener( 'click', event => {
                event.preventDefault();
                const newPosY = document.querySelector( '.hero' ).offsetHeight - IDEC.header.offsetHeight;
                window.scrollTo( 0, newPosY );
            });

        },

        // Binding header
        bindHeader : function(){

            IDEC.header = document.querySelector( 'body > div > header' );
            IDEC.menu = document.querySelector( '#menu' );
            IDEC.menuToggle = document.querySelector( '[data-action="toggle-menu"]' );

            // Toggle menu
            IDEC.menuToggle.addEventListener( 'click', event => {
                IDEC.toggleMenu();
            });

            // Close menu
            IDEC.menu.querySelectorAll( 'a' ).forEach( link => {
                link.addEventListener( 'click', event => {
                    IDEC.closeMenu();
                });
            });
        
        },

        // Bind footer
        bindFooter : function(){

            // Footer element
            IDEC.footer = document.querySelector( 'body > div > footer' );

            // Set button floating
            IDEC.main.classList.add( 'fix-button-news' );

        },

        // Bind scroll
        bindScroll : function(){
            window.addEventListener( 'scroll', event => {
                IDEC.checkScroll();
            });
            IDEC.checkScroll();
        },

        // Check scroll position
        checkScroll : function(){
            
            // Header
            if( IDEC.scrollPosition() > 0 ){
                if( !IDEC.main.classList.contains( 'scrolled' ) ){
                    IDEC.main.classList.add( 'scrolled' );
                }
            }
            else {
                if( IDEC.main.classList.contains( 'scrolled' ) ){
                    IDEC.main.classList.remove( 'scrolled' );
                }
            }

            // Footer
            const 
                limitTop = document.querySelector( 'body > div > header' ).offsetHeight,
                scrollTop = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop
            ;
            if( scrollTop > limitTop ){
                IDEC.main.classList.add( 'show-button-news' );
            }
            else {
                IDEC.main.classList.remove( 'show-button-news' );
            }

        },

        scrollHeight : function(){
            return Math.max(
                document.body.scrollHeight, document.documentElement.scrollHeight,
                document.body.offsetHeight, document.documentElement.offsetHeight,
                document.body.clientHeight, document.documentElement.clientHeight
            );
        },

        // Toggle mobile menu
        toggleMenu : function(){
            if( IDEC.main.classList.contains( 'menu-opened' ) ){
                IDEC.closeMenu();
            }
            else {
                IDEC.openMenu();
            }
        },

        // Open menu mobile
        openMenu : function(){
            IDEC.main.classList.add( 'menu-opened' );
            Navigation.lockScroll();
        },

        // Close menu mobile
        closeMenu : function(){
            IDEC.main.classList.remove( 'menu-opened' );
            Navigation.unlockScroll();
        },

        bindSliders : function(){

            const 
                defaults = document.querySelectorAll( '.list-squares,.list-dados' ),
                vWidth = IDEC.vWidth()
            ;

            // Default sliders
            defaults.forEach( itemSlider => {
                
                // Prepare slider
                const sliderSettings = IDEC.prepareSlider( itemSlider );

                // Others settings\
                if( itemSlider.classList.contains( 'list-dados' ) ){
                    sliderSettings.slidesPerView = 1;
                    sliderSettings.autoHeight = true;
                }
                else {
                    sliderSettings.slidesPerView = ( vWidth > 480 ? 'auto' : 1 );
                    sliderSettings.spaceBetween = 24;
                }

                itemSlider.slider = new Swiper( itemSlider, sliderSettings );                    
           
            });

            // Impactos
            if( vWidth <= 480 ){

                impactos = document.querySelector( '.impactos .list-style--1' );
                const impactosSettings = IDEC.prepareSlider( impactos );
                impactosSettings.spaceBetween = 24;
                impactosSettings.autoHeight = true;
                impactos.slider = new Swiper( impactos, impactosSettings ); 
                
            }

            // Hero
            const listHero = document.querySelector( '.list-hero' );
            if( listHero ){

                // Prepare slider
                const listHeroWrapper = '<div class="swiper-wrapper">' + listHero.innerHTML + '</div>';
                listHero.innerHTML = listHeroWrapper;
                listHero.classList.add( 'swiper' );
                listHero.querySelectorAll( '.list-item' ).forEach( slide => { slide.classList.add( 'swiper-slide' ) });
                listHero.slider = new Swiper( listHero, {
                    autoHeight : true,
                    autoplay: {
                        delay: 4000,
                    },
                    loop: true,
                    speed: 600,
                }); 

            }

        },

        prepareSlider : function( slider ){

            const 
                vWidth = IDEC.vWidth(),
                wrapper = '<div class="swiper-wrapper">' + slider.innerHTML + '</div>'
            ;

            // Settings
            let sliderSettings = {
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                }
            };

            slider.innerHTML = wrapper;
            slider.classList.add( 'swiper' );
            slider.querySelectorAll( '.list-item' ).forEach( slide => { slide.classList.add( 'swiper-slide' ) });

            // Controls
            const 
                controls = document.createElement( 'div' ),
                pager = document.createElement( 'div' ),
                arrows = document.createElement( 'div' ),
                prev = document.createElement( 'div' ),
                next = document.createElement( 'div' )
            ;
            controls.classList.add( 'swiper-controls' );
            pager.classList.add( 'swiper-pagination' );
                    
            // Insert controls
            slider.appendChild( controls );
            controls.appendChild( pager );

            // Arrows
            if( vWidth > 768 ){

                const 
                    arrows = document.createElement( 'div' ),
                    prev = document.createElement( 'div' ),
                    next = document.createElement( 'div' )
                ;
                arrows.classList.add( 'swiper-arrows' );
                prev.classList.add( 'swiper-button-prev' );
                next.classList.add( 'swiper-button-next' );

                controls.appendChild( arrows );
                arrows.appendChild( next );
                arrows.prepend( prev );

                sliderSettings.navigation = {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                };
            
            }

            return sliderSettings;

        },

        scrollTo : function( selector ){

            var $target = typeof( selector ) === 'object' ? selector : $( selector );            
            if( !$target.length ) return;

            var newY = $target.offset().top;
            var actualY = _window.scrollTop();
            var distance = actualY - newY;
            if( distance < 0 ) distance *= -1;

            var time = ( 600 - ( distance * 0.1 ) );
            if( time < 600 ) time = 600;
            
            var offset = $target.data( 'offset' );
            if( offset ){

                try {

                    var 
                        _json = JSON.parse( offset.split( "'" ).join( '"' ) )
                        _offset = false,
                        _vWidth = IDEC.vWidth()
                    ;

                    $.each( _json.breaks, function( _break ){
                        if( _vWidth <= _break ){
                            _offset = parseInt( this );
                            return false;
                        }
                    });

                    offset = ( !_offset ) ? parseInt( _json.initial ) : _offset;

                }
                catch( e ){
                    var _offset = parseInt( offset );
                    offset = _offset > 0 ? _offset : 0;
                }

            }
            else {
                offset = 0;
            }

            window.scrollTo( 0, ( newY - ( offset + 30 ) ) );

        },

        vWidth : function vWidth(){
            return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        },

        scrollPosition : function(){
            return window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop;
        },

        isMobile : function(){
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        }

    };
    window.addEventListener( 'DOMContentLoaded', () => {
        IDEC.init();
    });

})(jQuery);
